// MissionPage.js
import { getActiveElement } from '@testing-library/user-event/dist/utils';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

function MissionPage() {
  const location = useLocation();
  const selectedStratagems = location.state?.selectedStratagems || [];
  const [activeStratagem, setActiveStratagem] = useState(null);
  const [inputSequence, setInputSequence] = useState([]);
  const [tacControlActive, setTacControlActive] = useState(false);

  const handleStratagemSelect = (stratagem) => {
    setTacControlActive(true);
    setActiveStratagem(stratagem);
    setInputSequence([]); // Reset input when selecting a new stratagem
  };

  const handleInput = (direction) => {
    const newSequence = [...inputSequence, direction];
    setInputSequence(newSequence);

    // Check if sequence length matches the active stratagem's expected input
    if (newSequence.length === activeStratagem.input.length) {
      if (newSequence.join('') === activeStratagem.input) {
        // Sequence correct - do nothing or trigger a success action
      } else {
        // Sequence incorrect - clear the input sequence
        setTimeout(() => setInputSequence([]), 500); // Optional delay for feedback
      }
    }
  };

  const handleBack = () => {
    setInputSequence(inputSequence.slice(0, -1)); // Remove last input in sequence
  };

  const handleTacControl = () => {
    setTacControlActive(!tacControlActive);
    setInputSequence([]); // Reset input when TAC control is toggled
  };

  const isSequenceCorrect =
    activeStratagem && inputSequence.join('') === activeStratagem.input;
  
  if (isSequenceCorrect && tacControlActive) {
    handleTacControl();
  }

  return (
    <div className="min-h-screen bg-black text-white flex">
      {/* Left Section with Tactical Pad */}
      <div className="w-1/4 flex flex-col items-center justify-center">
        {!tacControlActive && (
          <div className="flex flex-col items-center">
            <img
              src="https://cdn.talosbot.xyz/files/helldivers_skull.png?nocache=true"
              alt="Helldivers Logo"
              className="w-32 h-32 mb-4"
            />
            <h1 className="text-xl font-bold">Stratagem Control</h1>
          </div>
        )}

        {tacControlActive && (
          <div className="grid grid-cols-3 gap-4 items-center">
            <button className="p-2 bg-transparent rounded-lg w-16 h-16" disabled />
            <button
              className="p-2 bg-orange-500 rounded-lg w-16 h-16 hover:bg-orange-400 items-center justify-center"
              onClick={() => handleInput('u')}
            >
              <img src="/Up_Arrow.webp" alt="Up arrow" />
            </button>
            <button className="p-2 bg-transparent rounded-lg w-16 h-16" disabled />

            <button
              className="p-2 bg-orange-500 rounded-lg w-16 h-16 hover:bg-orange-400 items-center justify-center"
              onClick={() => handleInput('l')}
            >
              <img src="/Left_Arrow.webp" alt="Left arrow" />
            </button>
            <button
              className="p-2 bg-orange-500 rounded-lg w-16 h-16"
              onClick={handleBack}
            >
              ⟲ {/* Back button icon */}
            </button>
            <button
              className="p-2 bg-orange-500 rounded-lg w-16 h-16 hover:bg-orange-400 items-center justify-center"
              onClick={() => handleInput('r')}
            >
              <img src="/Right_Arrow.webp" alt="Right arrow" />
            </button>

            <button className="p-2 bg-transparent rounded-lg w-16 h-16" disabled />
            <button
              className="p-2 bg-orange-500 rounded-lg w-16 h-16 hover:bg-orange-400 items-center justify-center"
              onClick={() => handleInput('d')}
            >
              <img src="/Down_Arrow.webp" alt="Down arrow" />
            </button>
            <button className="p-2 bg-transparent rounded-lg w-16 h-16" disabled />
          </div>
        )}
      </div>

      {/* Center Section with Stratagems */}
      <div className="w-3/4 flex flex-col space-y-4 ml-10">
        <h2 className="text-2xl font-bold mb-4">Select a Stratagem</h2>
        <div className="grid grid-cols-2 gap-4">
          {selectedStratagems.map((stratagem) => (
            <button
              key={stratagem.name}
              onClick={() => handleStratagemSelect(stratagem)}
              className="bg-[#2a2a2a] p-4 rounded-lg flex items-center space-x-4 hover:bg-[#2f2f2f]"
            >
              <img src={stratagem.image} alt="Stratagem Icon" className="w-12 h-12" />
              <span className="text-lg">{stratagem.name}</span>
            </button>
          ))}
        </div>

        {/* Selected Stratagem and Status Message */}
        {activeStratagem && (
          <div className="mt-6 p-4 bg-[#2a2a2a] rounded-lg">
            <h3 className="text-xl font-semibold">Sequence for {activeStratagem.name}</h3>
            <div className="flex mt-4 space-x-2">
              {Array.from(activeStratagem.input).map((char, index) => {
                const arrowMap = {
                  u: '/Up_Arrow.webp',
                  d: '/Down_Arrow.webp',
                  l: '/Left_Arrow.webp',
                  r: '/Right_Arrow.webp',
                };
                const inputChar = inputSequence[index];
                const isCorrect = inputChar === char;
                const isFilled = index < inputSequence.length;
                // Set border color based on match
                const borderColor = isFilled
                  ? isCorrect
                    ? 'border-yellow-400'
                    : 'border-red-400'
                  : '';

                return (
                  <img
                    key={index}
                    src={arrowMap[char]}
                    alt={`${char.toUpperCase()} Arrow`}
                    className={`w-8 h-8 border-2 ${borderColor}`}
                  />
                );
              })}
            </div>

            <p className="mt-4 text-lg">
              {isSequenceCorrect
                ? 'Sequence Correct! Stratagem Ready!'
                : inputSequence.length > 0
                ? 'Enter the Sequence'
                : ''}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default MissionPage;
