// StratagemPage.js
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function StratagemPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const selectedStratagems = location.state?.selectedStratagems || [];

  const handleStartMission = () => {
    navigate('/mission-page', { state: { selectedStratagems } });
  };

  return (
    <div className="p-6 bg-black min-h-screen text-white">
      <h2 className="text-2xl font-bold mb-6">Mission Stratagems</h2>
      <div className="space-y-6">
        {selectedStratagems.map((stratagem) => (
          <div key={stratagem.name} className="bg-[#2a2a2a] p-4 rounded-lg flex items-center space-x-4">
            <img src={stratagem.image} alt="Stratagem Icon" className="w-16 h-16" />
            <div>
              <h3 className="text-xl font-semibold">{stratagem.name}</h3>
              <div className="flex space-x-1 mt-2">
                {Array.from(stratagem.input).map((char, index) => {
                  const arrowMap = {
                    u: '/Up_Arrow.webp',
                    d: '/Down_Arrow.webp',
                    l: '/Left_Arrow.webp',
                    r: '/Right_Arrow.webp',
                  };
                  return (
                    <img key={index} alt={`${char.toUpperCase()} Arrow`} src={arrowMap[char]} className="w-6 h-6" />
                  );
                })}
              </div>
              <p className="mt-2 text-sm">Cooldown: {stratagem.cooldown}s</p>
              <p className="text-sm">Uses: {stratagem.uses}</p>
              <p className="text-sm">Activation Time: {stratagem.activation}s</p>
            </div>
          </div>
        ))}
      </div>
      <button
        onClick={handleStartMission}
        className="mt-8 p-3 bg-orange-500 text-white rounded-lg hover:bg-orange-400"
      >
        Proceed to Mission
      </button>
    </div>
  );
}

export default StratagemPage;
