// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home.js';
import MissionSelect from './MissionSelect.js';
import StratagemPage from './StratagemPage.js';
import MissionPage from './MissionPage.js';
function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/mission-select" element={<MissionSelect />} />
          <Route path="/stratagem-page" element={<StratagemPage />} />
          <Route path="/mission-page" element={<MissionPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
