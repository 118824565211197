// src/StratagemData.js
const StratagemData = {
    "Supply: Backpacks": [
        { "name": "LIFT-850 Jump Pack", "input": "duudu", "cooldown": 480, "uses": 0, "activation": 9.75, "image": "LIFT-850icon.webp", "color": "blue" },
        { "name": "B-1 Supply Pack", "input": "dlduud", "cooldown": 480, "uses": 0, "activation": 9.75, "image": "B-1icon.webp", "color": "blue" },
        { "name": "AX/LAS-5 \"Guard Dog\" Rover", "input": "dulurr", "cooldown": 480, "uses": 0, "activation": 9.75, "image": "AX-LAS-5icon.webp", "color": "blue" },
        { "name": "SH-20 Ballistic Shield Backpack", "input": "dlddul", "cooldown": 480, "uses": 0, "activation": 9.75, "image": "SH-20icon.webp", "color": "blue" },
        { "name": "SH-32 Shield Generator Pack", "input": "dulrlr", "cooldown": 480, "uses": 0, "activation": 9.75, "image": "SH-32icon.webp", "color": "blue" },
        { "name": "AX/AR-23 \"Guard Dog\"", "input": "dulurd", "cooldown": 480, "uses": 0, "activation": 9.75, "image": "AX-AR-23icon.webp", "color": "blue" }
    ],
    "Supply: Support Weapons": [
        { "name": "MG-43 Machine Gun", "input": "dldur", "cooldown": 480, "uses": 0, "activation": 4.75, "image": "MG-43icon.webp", "color": "blue" },
        { "name": "APW-1 Anti-Materiel Rifle", "input": "dlrud", "cooldown": 480, "uses": 0, "activation": 4.75, "image": "APW-1icon.webp", "color": "blue" },
        { "name": "M-105 Stalwart", "input": "dlduul", "cooldown": 480, "uses": 0, "activation": 4.75, "image": "M-105icon.webp", "color": "blue" },
        { "name": "EAT-17 Expendable Anti-tank", "input": "ddlur", "cooldown": 480, "uses": 0, "activation": 4.75, "image": "EAT-17icon.webp", "color": "blue" },
        { "name": "MLS-4X Commando", "input": "dludr", "cooldown": 480, "uses": 0, "activation": 4.75, "image": "CommandoIcon.webp", "color": "blue" },
        { "name": "GR-8 Recoilless Rifle", "input": "dludr", "cooldown": 480, "uses": 0, "activation": 4.75, "image": "GR-8icon.webp", "color": "blue" },
        { "name": "FLAM-40 Flamethrower", "input": "dludu", "cooldown": 480, "uses": 0, "activation": 4.75, "image": "FLAM-40icon.webp", "color": "blue"},
        { "name": "AC-8 Autocannon", "input": "dlduur", "cooldown": 480, "uses": 0, "activation": 4.75, "image": "AC-8icon.webp", "color": "blue" },
        { "name": "MG-206 Heavy Machine Gun", "input": "dludd", "cooldown": 480, "uses": 0, "activation": 4.75, "image": "MG206Icon.webp", "color": "blue" },
        { "name": "RS-422 Railgun", "input": "drdulr", "cooldown": 480, "uses": 0, "activation": 4.75, "image": "RS-422icon.webp", "color": "blue" },
        { "name": "FAF-14 Spear Launcher", "input": "ddudd", "cooldown": 480, "uses": 0, "activation": 4.75, "image": "FAF-14icon.webp", "color": "blue" },
        { "name": "GL-21 Grenade Launcher", "input": "dluld", "cooldown": 480, "uses": 0, "activation": 4.75, "image": "GL-21icon.webp", "color": "blue" },
        { "name": "LAS-99 Quasar Cannon", "input": "ddulr", "cooldown": 480, "uses": 0, "activation": 4.75, "image": "LAS99Icon.webp", "color": "blue" },
        { "name": "RL-77 Airburst Rocket Launcher", "input": "duulr", "cooldown": 480, "uses": 0, "activation": 4.75, "image": "RL77ARLiconHD2.webp", "color": "blue" }
    ],
    "Supply: Other": [
        { "name": "EXO-45 Patriot Exosuit", "input": "drdulr", "cooldown": 480, "uses": 0, "activation": 4.75, "image": "EXO45.webp", "color": "blue" },
        { "name": "EXO-49 Emancipator Exosuit", "input": "drdulr", "cooldown": 480, "uses": 0, "activation": 4.75, "image": "EXO-49_Emancipator_Exosuit_Icon.webp", "color": "blue" }
    ],
    "Mission Stratagems": [ 
        { "name": "Reinforce", "input": "udrlu", "cooldown": 120, "uses": 5, "activation": 5, "image": "Reinforceicon.webp", "color": "yellow" },
        { "name": "SOS Beacon", "input": "udru", "cooldown": 0, "uses": 1, "activation": 5, "image": "SOSicon.webp", "color": "yellow" },
        { "name": "Resupply", "input": "ddur", "cooldown": 160, "uses": 0, "activation": 12, "image": "ResupplyIcon.webp", "color": "yellow" },
        { "name": "NUX-223 Hellbomb", "input": "duldurdu", "cooldown": 0, "uses": 0, "activation": 0, "image": "Hellbomb_Icon.webp", "color": "yellow" },
        { "name": "SSSD Delivery", "input": "udrlu", "cooldown": 0, "uses": 0, "activation": 0, "image": "DeliverSSSDicon.webp", "color": "yellow" },
        { "name": "Seismic Probe", "input": "uulrdd", "cooldown": 0, "uses": 1, "activation": 9, "image": "Seismic_probe_icon.webp", "color": "yellow" },
        { "name": "Upload Data", "input": "lruuu", "cooldown": 0, "uses": 0, "activation": 0, "image": "HD2_Super_Earth_Flag.webp", "color": "yellow" },
        { "name": "Eagle Rearm", "input": "uulur", "cooldown": 120, "uses": 0, "activation": 0, "image": "HD2_Eagle_Rearm_Icon.webp", "color": "yellow" },
        { "name": "SEAF Artillery", "input": "ruud", "cooldown": 120, "uses": 0, "activation": 0, "image": "HD2_SEAF_Artillery.webp", "color": "yellow" }
    ],
    "Defensive Stratagems": [
        { "name": "E/MG-101 HMG Emplacement", "input": "dulrrl", "cooldown": 180, "uses": 0, "activation": 4.75, "image": "HMGTurreticon.webp", "color": "green" },
        { "name": "FX-12 Shield Generator Relay", "input": "ddlrlr", "cooldown": 180, "uses": 0, "activation": 4.75, "image": "Shieldrelayicon.webp", "color": "green" },
        { "name": "A/ARC-3 Tesla Tower", "input": "durulr", "cooldown": 180, "uses": 0, "activation": 4.75, "image": "Teslaicon.webp", "color": "green" },
        { "name": "MD-6 Anti-Personnel Minefield", "input": "dlur", "cooldown": 180, "uses": 0, "activation": 4.75, "image": "Minefieldicon.webp", "color": "green" },
        { "name": "MD-14 Incendiary Mines", "input": "dlld", "cooldown": 180, "uses": 0, "activation": 4.75, "image": "Minefieldicon.webp", "color": "green" },
        { "name": "MD-17 Anti-Tank Mines", "input": "dluu", "cooldown": 180, "uses": 0, "activation": 4.75, "image": "Minefieldicon.webp", "color": "green" },
        { "name": "A/MG-43 Machine Gun Sentry", "input": "durru", "cooldown": 180, "uses": 0, "activation": 4.75, "image": "MGsentryicon.webp", "color": "green" },
        { "name": "A/M-12 Mortar Sentry", "input": "durrd", "cooldown": 180, "uses": 0, "activation": 4.75, "image": "Mortarsentryicon.webp", "color": "green" },
        { "name": "A/AC-8 Autocannon Sentry", "input": "durulu", "cooldown": 180, "uses": 0, "activation": 4.75, "image": "Autocannoasentryicon.webp", "color": "green" }
    ],
    "Eagle": [
        { "name": "Eagle 110MM Rocket Pods", "input": "dluulu", "cooldown": 100, "uses": 1, "activation": 7.5, "image": "Eagle_110MM_Rocket_Pods_Icon.webp", "color": "red" },
        { "name": "Eagle 500KG Bomb", "input": "urddd", "cooldown": 180, "uses": 1, "activation": 5.0, "image": "Eagle_500KG_Bomb_Icon.webp", "color": "red" },
        { "name": "Eagle Airstrike", "input": "dudldr", "cooldown": 150, "uses": 1, "activation": 6.0, "image": "Eagle_Airstrike_Icon.webp", "color": "red" },
        { "name": "Eagle Cluster Bomb", "input": "drdldr", "cooldown": 210, "uses": 1, "activation": 8.5, "image": "Eagle_Cluster_Bomb_Icon.webp", "color": "red" },
        { "name": "Eagle Napalm Airstrike", "input": "dulldd", "cooldown": 200, "uses": 1, "activation": 8.0, "image": "Eagle_Napalm_Airstrike_Icon.webp", "color": "red" },
        { "name": "Eagle Smoke Strike", "input": "durrdl", "cooldown": 60, "uses": 1, "activation": 4.0, "image": "Eagle_Smoke_Strike_Icon.webp", "color": "red" },
        { "name": "Eagle Strafing Run", "input": "drdldr", "cooldown": 120, "uses": 1, "activation": 6.5, "image": "Eagle_Strafing_Run_Icon.webp", "color": "red" },
        { "name": "Eagle Rearm", "input": "uulur", "cooldown": 120, "uses": 0, "activation": 0, "image": "HD2_Eagle_Rearm.webp", "color": "yellow" }
    ],
    "Orbital": [
        { "name": "Orbital 120MM HE Barrage", "input": "rddrlu", "cooldown": 90, "uses": 1, "activation": 5.5, "image": "Orbital_120MM_HE_Barrage_Icon.webp", "color": "purple" },
        { "name": "Orbital 380MM HE Barrage", "input": "rdrdlu", "cooldown": 130, "uses": 1, "activation": 6.5, "image": "Orbital_380MM_HE_Barrage_Icon.webp", "color": "purple" },
        { "name": "Orbital Airburst Strike", "input": "rrludr", "cooldown": 100, "uses": 1, "activation": 6.0, "image": "Orbital_Airburst_Strike_Icon.webp", "color": "purple" },
        { "name": "Orbital EMS Strike", "input": "rduldr", "cooldown": 140, "uses": 1, "activation": 7.0, "image": "Orbital_EMS_Strike_Icon.webp", "color": "purple" },
        { "name": "Orbital Gas Strike", "input": "rldldu", "cooldown": 110, "uses": 1, "activation": 6.0, "image": "Orbital_Gas_Strike_Icon.webp", "color": "purple" },
        { "name": "Orbital Gatling Barrage", "input": "rdldul", "cooldown": 70, "uses": 1, "activation": 4.5, "image": "Orbital_Gatling_Barrage_Icon.webp", "color": "purple" },
        { "name": "Orbital Laser", "input": "rurudu", "cooldown": 150, "uses": 1, "activation": 7.5, "image": "Orbital_Laser_Icon.webp", "color": "purple" },
        { "name": "Orbital Precision Strike", "input": "rdulld", "cooldown": 100, "uses": 1, "activation": 5.5, "image": "Orbital_Precision_Strike_Icon.webp", "color": "purple" },
        { "name": "Orbital Railcannon Strike", "input": "rurrdl", "cooldown": 160, "uses": 1, "activation": 8.0, "image": "Orbital_Railcannon_Strike_Icon.webp", "color": "purple" },
        { "name": "Orbital Smoke Strike", "input": "rdrdlu", "cooldown": 60, "uses": 1, "activation": 3.5, "image": "Orbital_Smoke_Strike_Icon.webp", "color": "purple" },
        { "name": "Orbital Walking Barrage", "input": "rrldur", "cooldown": 180, "uses": 1, "activation": 7.5, "image": "Orbital_Walking_Barrage_Icon.webp", "color": "purple" }
    ]
};

export default StratagemData;