// MissionSelect.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StratagemData from './StratagemData';

function MissionSelect() {
    const [selectedStratagems, setSelectedStratagems] = useState([]);
    const navigate = useNavigate();

    const handleStratagemSelection = (stratagem) => {
        if (selectedStratagems.length < 4) {
            setSelectedStratagems([...selectedStratagems, stratagem]);
        }
    };

    const startMission = () => {
        if (selectedStratagems.length === 4) {
            navigate('/stratagem-page', { state: { selectedStratagems } });
        } else {
            alert('Select 4 Stratagems to proceed');
        }
    };

    return (
        <div className="flex flex-col h-screen bg-black text-white p-5">
            <h2 className="text-2xl font-bold mb-4">Select Your Stratagems</h2>
            <div className="flex-grow overflow-y-auto">
                {Object.entries(StratagemData).map(([category, items]) => (
                    <div key={category} className="mb-6">
                        <h3 className="text-xl font-semibold mb-2">{category}</h3>
                        <div className="grid grid-cols-2 gap-4">
                            {items.map((item) => (
                                <button
                                    key={item.name}
                                    className={`p-2 border rounded-lg transition duration-200 
                                                ${selectedStratagems.includes(item) ? 'bg-orange-500' : 'bg-gray-800 hover:bg-gray-700'}
                                                focus:outline-none focus:ring-2 focus:ring-orange-300`}
                                    onClick={() => handleStratagemSelection(item)}
                                >
                                    {item.name}
                                </button>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <button
                onClick={startMission}
                className="mt-4 p-3 text-lg bg-orange-500 text-white rounded-lg hover:bg-orange-400 focus:outline-none focus:ring-2 focus:ring-orange-300 w-full"
            >
                Start Mission
            </button>
        </div>
    );
}

export default MissionSelect;
