// Home.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

function Home() {
    const navigate = useNavigate();
    return (
        <div className="ml-4 flex flex-col h-screen bg-black text-white p-5 bg-cover bg-center"
             style={{ 
                 backgroundImage: "url('https://cdn-f.talosbot.xyz/superearthtransparent.png')", 
                 backgroundSize: "30%",  // Adjust size as needed (50% is an example)
                 backgroundPosition: "center", // Aligns logo to the top center
                 backgroundRepeat: "no-repeat" // Prevents repeating of the image
             }}>
            {/* Top Section for Welcome Text */}
            <div className="flex justify-between items-start">
                <div className="flex flex-col max-w-s">
                    <h2 className="text-lg">Welcome, Helldiver</h2>
                    <h3 className="text-m">Active Ship: SES Defender of Family Values</h3>
                </div>
            </div>

            {/* Center Logo - now as background */}
            <div className="flex-grow flex items-center justify-center">
                {/* Placeholder for other content or spacing if needed */}
            </div>

            {/* Top Right Poster with Title */}
            <div className="flex flex-col items-end">
                <h1 className="text-lg">Ministry Of Truth Bulletin</h1>
                <img
                    src="https://cdn.talosbot.xyz/files/Fight_For_Her_Super_Earth_Poster.png"
                    alt="Helldivers Poster"
                    className="max-w-xs h-60 mr-6" // Smaller size for the poster
                />
            </div>

            {/* Bottom Left Start Mission Button */}
            <button
                className="mt-2 p-2 text-sm bg-orange-500 text-white rounded-lg hover:bg-orange-400 focus:outline-none focus:ring-2 focus:ring-orange-300 w-full max-w-xs"
                onClick={() => navigate('/mission-select')}
            >
                Start Mission
            </button>
        </div>
    );
}



export default Home;
